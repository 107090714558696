.rules__section,
.scores__section {
	/* position: relative; */
	background-color: var(--COLOR-WHITE);
	padding: 2rem 3rem;
	border-radius: 8px;
	min-width: 30rem;
	max-width: 50rem;
	margin: 5px;
	color: var(--COLOR-BLACK);
}

.rules__section .h3,
.scores__section .h3 {
	color: var(--COLOR-BLACK);
}
.scores__section {
	display: flex;
	flex-direction: column;
	gap: 1rem;
}

.rules__header,
.score__header {
	align-items: center;
	display: flex;
	justify-content: space-between;
}

.rules__section .btn--dismiss,
.scores__section .btn--dismiss {
	border: none;
	font-size: 1rem;
	padding: 4px;
	display: flex;
	justify-content: center;
	align-items: center;
	background-color: var(--COLOR-PRIMARY);
	color: var(--COLOR-WHITE);
	transition: background-color 30ms ease-in;
}

.rules__section .btn--dismiss:hover,
.scores__section .btn--dismiss:hover {
	background-color: var(--COLOR-PRIMARY);
}

.rules__section .btn--dismiss i,
.scores__section .btn--dismiss i {
	font-size: 2.4rem;
}

.rules__list {
	margin-top: 1.5rem;
	font-size: 2rem;
	padding: 0 2.4rem;
	font-weight: 400;
	display: flex;
	flex-direction: column;
	gap: 5px;
}

.rules__list li {
}

.rules__btn,
.scores__btn {
	display: flex;
	justify-content: center;
	align-items: center;
	align-content: center;
	flex-wrap: wrap;
	transition: background-color 0.1 ease-in;
}

.rules__btn .btn {
	padding: 8px 4rem;
}

.scores__btn {
	justify-content: space-evenly;
	width: 100%;
	gap: 1.5rem;
}

.scores__btn .btn {
	padding: 8px 2rem;
}

.score__text {
	text-align: center;
	font-size: 2.2rem;
}

.score__text span {
	font-size: 2.5rem;
	font-weight: 500;
}
