.nav,
.notes_nav {
	display: flex;
	flex-flow: column nowrap;
	grid-area: aside;
}

.nav__items {
	margin-left: 2rem;
	display: flex;
	flex-flow: column nowrap;
	list-style-type: none;
	gap: 2rem;
	padding-top: 3rem;
	color: var(--COLOR-TXT);
	position: fixed;
}

.nav__list_item {
	display: flex;
	font-size: 2.2rem;
	align-items: center;
	gap: 2rem;
}

.nav__list_item:hover {
	color: var(--COLOR-PRIMARY);
}

/* HOME RESPONSIVENESS */
.nav--mobile {
	display: none;
	flex-flow: row nowrap;
	grid-area: aside_mobile;
	width: 100%;
	align-items: center;
}

.nav--mobile .nav__items--mobile {
	margin-left: 0;
	display: flex;
	flex-flow: row nowrap;
	list-style-type: none;
	justify-content: space-evenly;
	align-items: center;
	width: 100%;
	position: fixed;
	bottom: 3.8rem;
	padding: 1.2rem 0;
	background: var(--COLOR-BG);
	color: var(--COLOR-TXT);
}

.nav--mobile .nav__list_item {
	font-size: 3.2rem;
}

/* triggers at 700 */

body .note--displayed {
	width: 31rem;
}

body .note_editor_section {
	padding: 1.5rem;
}

.filter__icon {
	display: none;
	position: fixed;
	top: 13rem;
	left: 1rem;
	z-index: var(--z-index-3);
}
