.main--home_page {
	grid-area: main;
	flex-flow: row nowrap;
	min-height: 100%;
}

.scroll_to_top_icon {
	position: fixed;
	bottom: 7rem;
	right: 1.5rem;
	z-index: var(--z-index-2);
}

.main--homepage {
	display: flex;
	flex-flow: column nowrap;
	padding: 2rem 1rem 1rem 1rem;
	gap: 3rem;
	grid-area: main;
}

.homepage__section {
	display: flex;
	flex-flow: column nowrap;
	gap: 2rem;
}

.categories__items {
	display: flex;
	flex-flow: row wrap;
	gap: 2rem;
}

.quiz__items {
	display: flex;
	flex-flow: row wrap;
	gap: 2rem;
	margin-bottom: 1.3rem;
}

.categories__list {
	list-style-type: none;
}

.bottom__buffer {
	margin-bottom: 11rem;
}

@media only screen and (max-width: 780px) {
	.categories__items {
		justify-content: center;
		text-align: center;
	}

	.quiz__items {
		justify-content: center;
		text-align: center;
	}

	.section__heading,
	.homepage__section {
		justify-content: center;
		text-align: center;
	}

	.video__banner {
		width: 98%;
		aspect-ratio: 2.8/1;
		object-fit: fill;
		margin: auto;
	}
}
